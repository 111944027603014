import axios from "axios";
import { CittaModel } from "./cittaModel";

class LocalizationService {
    private baseUrl: string = "https://conf.goodie.dev/localization/";

    async getDetailByCitta(citta: string): Promise<CittaModel> {
        let citta_detail = new CittaModel();
        citta_detail.citta = citta;

        try {
            debugger;
            const response = await axios.get(
                this.baseUrl + "citta/getDetailByCitta/" + citta_detail.citta
            );
            citta_detail = response.data;

            return citta_detail;
        } catch (e) {
            console.error(e);
        }
    }
}

export default LocalizationService;
