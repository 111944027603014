<script lang="ts">
export default {
    name: "Footer",
};
</script>

<template>
    <div>
        <div class="text-center text-base p-7">
            Everset Srl P.I./C.F. 03503041208 <br />
            Via Pietro Giardini 476/N &nbsp; 41126 Modena (MO) - Italy
            <div>
                <ul class="flex justify-center gap-4 mt-4">
                    <li>
                        <a
                            target="_blank"
                            href="https://everset.it/cookie-policy/it/cookies-policy.pdf"
                            class="underline"
                            >Cookie Policy</a
                        >
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="https://everset.it/privacy-policy/it/privacy-policy.pdf"
                            class="underline"
                            >Privacy Policy</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
a,
div {
    font-size: 14px;
}

ul {
    list-style-type: none;
}
</style>
