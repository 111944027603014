export class CittaModel {
    public id_provincia: number;
    public id_regione: number;
    public regione: string;
    public provincia: string;
    public citta: string;
    
    /**
     *
     */
    constructor() {}
}
